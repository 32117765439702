<template>
    <div id="cities">
      <div class="container-fluid">
        <!-- Add Edit Section -->
        <div class="add-section" v-if="canAccess(['store-city','update-city'])">
          <b-modal id="cities-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('city')" @ok="submit">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <label for="">{{ $t('Governorate') }}</label>
                <v-select v-model="payload.governorate_id" :options="governorates.data" :reduce="(option)=>option.id" :label="`${$i18n.locale}_name`" />
              </div>
              <div class="feild">
                <label for="">{{ $t('ar-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.ar_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('en-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.en_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('kr-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.kr_name"
                  required
                ></b-form-input>
              </div>
            </b-form>
          </b-modal>
        </div>
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-input
                      type="text"
                      :placeholder="$t('filter-name')"
                      v-model="filterData[`${$i18n.locale}_name`]"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-select class="h-100" value-field="id" :text-field="`${$i18n.locale}_name`" v-model="filterData.governorate_id" :options="governorates.data">
                      <template #first>
                        <b-form-select-option value="">{{ $t('filter-gov') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-col>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('cities-list')}}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button v-if="canAccess(['store-city'])" variant="primary" @click="addInit" size="sm">{{$t('add')}}</b-button>
            </template>
            <template v-slot:body>
              <b-table responsive :items="cities.data" v-if="canAccess(['get-city','update-city','delete-city'])" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(actions)="data">
                  <div class="actions-list d-flex align-items-center">
                      <b-button v-if="canAccess(['update-city'])" v-b-tooltip.top="$t('edit')" variant=" iq-bg-success" @click="update(data.item)"  size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                      <!-- <b-button v-if="canAccess(['delete-city'])" v-b-tooltip.top="$t('remove')" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="cities.total"
                  :per-page="cities.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'governorates',
  data () {
    return {
      filterData: {
        page: 1,
        take: 10,
        ar_name: '',
        en_name: '',
        kr_name: '',
        governorate_id: ''
      },
      payload: {
        ar_name: '',
        en_name: '',
        kr_name: '',
        governorate_id: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('name'),
          key: `${this.$i18n.locale}_name`
        },
        {
          label: this.$t('governorate'),
          key: `governorate.${this.$i18n.locale}_name`
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('created-by'),
          key: 'created_by.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getCities(this.filterData)
    },
    addInit () {
      this.$bvModal.show('cities-modal')
    },
    update (data) {
      Object.assign(this.payload, data)
      this.$bvModal.show('cities-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeCities(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addCities(this.payload).then(() => {
          this.$bvModal.hide('cities-modal')
          this.payload = this.resetObject(this.payload)
          this.getCities()
        })
      } else {
        this.updateCities(this.payload).then(() => {
          this.$bvModal.hide('cities-modal')
          this.payload = this.resetObject(this.payload)
          this.getCities()
        })
      }
    },
    changeStatus () {},
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.imgPreview = ''
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getCities(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getCities({
      take: 10,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: '',
      governorate_id: ''
    })
    this.getGovernorates({
      take: 100,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: ''
    })
  }
}
</script>
